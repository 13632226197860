<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Wszystkie zgłoszenia</h1>

            <ErrorSubscription :isCustomerActive="isCustomerActive()" />

            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Nowe zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="newSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj w widocznych"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="newSignals.selected"
                                      :headers="newSignals.headers"
                                      :items="filteredNewSignals"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :server-items-length="totalItemsNew"
                                      @update:page="handlePageUpdateNew"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>

                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Otwarte zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="openSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj w widocznych"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="openSignals.selected"
                                      :headers="openSignals.headers"
                                      :items="filteredOpenSignals"
                                      item-key="identifier"
                                      :loading="loading2"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage2"
                                      :hide-default-footer="false"
                                      :server-items-length="totalItemsOpen"
                                      @update:page="handlePageUpdateOpen"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>

                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }" v-if="canArchivize">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="addToArchive(item)" v-on="on">
                                            <v-icon color="brown">mdi-archive</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>ZARCHIWIZUJ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="isCustomerActive()">
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Archiwalne zgłoszenia</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="archiveSignals.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj w widocznych"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="archiveSignals.selected"
                                      :headers="archiveSignals.headers"
                                      :items="filteredArchiveSignals"
                                      item-key="identifier"
                                      :loading="loading3"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage3"
                                      :hide-default-footer="false"
                                      :server-items-length="totalItemsArchive"
                                      @update:page="handlePageUpdateArchive"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                            <template v-slot:[`item.icon`]="{ item }">
                                <v-icon v-if="item.icon">
                                    mdi-paperclip
                                </v-icon>
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon v-if="item.type == 2">
                                    mdi-phone
                                </v-icon>
                                <v-icon v-if="item.type == 3">
                                    mdi-email-outline
                                </v-icon>
                            </template>
                            <template v-slot:[`item.show`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="showSignal(item)" v-on="on">
                                            <v-icon color="green">mdi-open-in-new</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POKAŻ</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }" v-if="canArchivize">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="deleteSignal(item)" v-on="on">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>USUŃ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
    export default {
        name: 'All',
        components: {
            ErrorSubscription
        },
        data() {
            return {
                newSignals: {
                    selected: [],
                    search: '',
                    headers: [
                        {
                            text: 'Identyfikator',
                            align: 'start',
                            sortable: false,
                            value: 'identifier',
                            width: "1%"
                        },
                        { text: '', value: 'icon', sortable: false, width: "1%" },
                        { text: '', value: 'type', sortable: false, width: "1%" },
                        { text: 'Data wpływu', value: 'datetime', sortable: false },
                        { text: 'Dni', value: 'days', sortable: false },
                        { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                        { text: 'Etykieta', value: 'title', sortable: false },
                        { text: 'Kategoria', value: 'category', sortable: false },
                        { text: '', value: 'show', sortable: false },
                    ],
                    signals: [],
                },
                openSignals: {
                    selected: [],
                    search: '',
                    headers: [
                        {
                            text: 'Identyfikator',
                            align: 'start',
                            sortable: false,
                            value: 'identifier',
                            width: "1%"
                        },
                        { text: '', value: 'icon', sortable: false, width: "1%" },
                        { text: '', value: 'type', sortable: false, width: "1%" },
                        { text: 'Data wpływu', value: 'datetime', sortable: false },
                        { text: 'Dni', value: 'days', sortable: false },
                        { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                        { text: 'Etykieta', value: 'title', sortable: false },
                        { text: 'Kategoria', value: 'category', sortable: false },
                        { text: '', value: 'show', sortable: false },
                        { text: '', value: 'actions', sortable: false },
                    ],
                    signals: [],
                },
                archiveSignals: {
                    selected: [],
                    search: '',
                    headers: [
                        {
                            text: 'Identyfikator',
                            align: 'start',
                            sortable: false,
                            value: 'identifier',
                            width: "1%"
                        },
                        { text: '', value: 'icon', sortable: false, width: "1%" },
                        { text: '', value: 'type', sortable: false, width: "1%" },
                        { text: 'Data wpływu', value: 'datetime', sortable: false },
                        { text: 'Data zakończenia', value: 'archiveTime', sortable: false },
                        { text: 'Dni', value: 'days', sortable: false },
                        { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                        { text: 'Etykieta', value: 'title', sortable: false },
                        { text: 'Kategoria', value: 'category', sortable: false },
                        { text: '', value: 'show', sortable: false },
                        { text: '', value: 'actions', sortable: false },
                    ],
                    signals: [],
                },
                signals: [],
                signalsDbNew: [],
                signalsDbOpen: [],
                signalsDbArchive: [],
                canArchivize: false,
                itemsPerPage: 10,
                itemsPerPage2: 10,
                itemsPerPage3: 10,
                loading: true,
                loading2: true,
                loading3: true,
                totalItemsArchive: 10,
                totalItemsOpen: 10,
                totalItemsNew: 10,
                pageNumberNew: 1,
                pageNumberOpen: 1,
                pageNumberArchive: 1,
            }
        },
        computed: {
            filteredNewSignals() {
                const searchText = this.newSignals.search.toLowerCase();
                return this.newSignals.signals.filter(signal => Object.values(signal).some(value => String(value).toLowerCase().includes(searchText)));
            },
            filteredOpenSignals() {
                const searchText = this.openSignals.search.toLowerCase();
                return this.openSignals.signals.filter(signal => Object.values(signal).some(value => String(value).toLowerCase().includes(searchText)));
            },
            filteredArchiveSignals() {
                const searchText = this.archiveSignals.search.toLowerCase();
                return this.archiveSignals.signals.filter(signal => Object.values(signal).some(value => String(value).toLowerCase().includes(searchText)));
            },
        },
        async mounted() {
            try {

                if (this.isCustomerActive()) {
                    this.main = (await Service.allMessages(this.itemsPerPage)).data,
                        this.signalsDbNew = this.main.newSignals,
                        this.signalsDbOpen = this.main.openSignals,
                        this.signalsDbArchive = this.main.archiveSignals,
                        this.canArchivize = this.main.canArchivize,
                        this.totalItemsNew = this.main.totalItemNew,
                        this.totalItemsOpen = this.main.totalItemOpen,
                        this.totalItemsArchive = this.main.totalItemArchive,
                        this.openSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime', sortable: false },
                                { text: 'Dni', value: 'days', sortable: false },
                                { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                                { text: 'Etykieta', value: 'title', sortable: false },
                                { text: 'Kategoria', value: 'category', sortable: false },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbOpen,
                        },
                        this.loading2 = false,
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime', sortable: false },
                                { text: 'Dni', value: 'days', sortable: false },
                                { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                                { text: 'Etykieta', value: 'title', sortable: false },
                                { text: 'Kategoria', value: 'category', sortable: false },
                                { text: '', value: 'show', sortable: false },
                            ],
                            signals: this.signalsDbNew,
                        },
                        this.loading = false,
                        this.archiveSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime', sortable: false },
                                { text: 'Data zakończenia', value: 'archiveTime', sortable: false },
                                { text: 'Dni', value: 'days', sortable: false },
                                { text: 'Odpowiedzi', value: 'replaysCount', sortable: false },
                                { text: 'Etykieta', value: 'title', sortable: false },
                                { text: 'Kategoria', value: 'category', sortable: false },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbArchive,
                        },
                        this.loading3 = false
                }
               

            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                async handler() {
                    //if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        await this.loadRecords(this.itemsPerPage, 1);
                    //}
                },
                deep: true,
            },
            itemsPerPage2: {
                async handler() {
                    //if (this.itemsPerPage2 > 20 || this.itemsPerPage2 == -1) {
                        await this.loadRecords(this.itemsPerPage2, 2);
                    //}
                },
                deep: true,
            },
            itemsPerPage3: {
                async handler() {
                    //if (this.itemsPerPage3 > 20 || this.itemsPerPage3 == -1) {
                        await this.loadRecords(this.itemsPerPage3, 3);
                    //}
                },
                deep: true,
            },
        },
        methods: {
            async handlePageUpdateNew(newPage) {
                this.pageNumberNew = newPage;
                await this.loadRecords(this.itemsPerPage, 1);
            },
            async handlePageUpdateOpen(newPage) {
                this.pageNumberOpen = newPage;
                await this.loadRecords(this.itemsPerPage2, 2);
            },
            async handlePageUpdateArchive(newPage) {
                this.pageNumberArchive = newPage;
                await this.loadRecords(this.itemsPerPage3, 3);
            },
            isCustomerActive() {
                return this.$store.state.admin.customerIsActive;
            },
            showSignal(item) {
                this.$router.push({ name: 'Signal', params: { identifier: item.id } })
            },
            async addToArchive(item) {
                try {
                    if (confirm('Czy na pewno przenieść zgłoszenie o identyfikatorze: ' + item.identifier + ' do archiwum?')) {

                        var temp = {
                            ip: this.$store.state.ip,
                            id: item.id
                        }

                        var result = (await Service.addToArchive(temp));
                        if (result.status == 200) {
                            var index = this.openSignals.signals.findIndex(x => x.identifier == item.identifier);
                            if (index != -1) {
                                item.archiveTime = result.data;
                                this.archiveSignals.signals.push(item);
                                this.totalItemsOpen -= 1;
                                this.openSignals.signals.splice(index, 1);
                                this.totalItemsArchive += 1;
                            }
                        }
                    } else {
                        return;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async deleteSignal(item) {
                try {
                    if (confirm('Czy na pewno usunąć zgłoszenie o identyfikatorze: ' + item.identifier + '? Pamiętaj, ta operacja jest nieodwracalna.')) {

                        var temp = {
                            ip: this.$store.state.ip,
                            id: item.id
                        }

                        var result = (await Service.deleteSignal(temp));
                        if (result.status == 200) {
                            var index = this.archiveSignals.signals.findIndex(x => x.identifier == item.identifier);
                            if (index != -1) {
                                this.archiveSignals.signals.splice(index, 1);
                                this.totalItemsArchive -= 1;
                            }
                        }
                    } else {
                        return;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async loadRecords(count, type) {
                try {

                    var page = 1;

                    if (type == 1) {
                        this.loading = true;
                        page = this.pageNumberNew;
                    }
                        
                    if (type == 2) {
                        this.loading2 = true;
                        page = this.pageNumberOpen;
                    }
                        
                    if (type == 3) {
                        this.loading3 = true;
                        page = this.pageNumberArchive;
                    }
                        
                    var result = (await Service.getAllSignalsForType(count, type, page)).data;
                    
                    if (type == 1) {
                        this.signalsDbNew = result;
                        this.newSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                            ],
                            signals: this.signalsDbNew,
                        }
                    }
                    if (type == 2) {
                        this.signalsDbOpen = result;
                        this.openSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbOpen,
                        }
                    }
                    if (type == 3) {
                        this.signalsDbArchive = result;
                        this.archiveSignals = {
                            selected: [],
                            search: '',
                            headers: [
                                {
                                    text: 'Identyfikator',
                                    align: 'start',
                                    sortable: false,
                                    value: 'identifier',
                                    width: "1%"
                                },
                                { text: '', value: 'icon', sortable: false, width: "1%" },
                                { text: '', value: 'type', sortable: false, width: "1%" },
                                { text: 'Data wpływu', value: 'datetime' },
                                { text: 'Dni', value: 'days' },
                                { text: 'Odpowiedzi', value: 'replaysCount' },
                                { text: 'Etykieta', value: 'title' },
                                { text: 'Kategoria', value: 'category' },
                                { text: '', value: 'show', sortable: false },
                                { text: '', value: 'actions', sortable: false },
                            ],
                            signals: this.signalsDbArchive,
                        }
                    }

                    this.loading = false;
                    this.loading2 = false;
                    this.loading3 = false;
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="./Basic.scss" lang="scss">
</style>
