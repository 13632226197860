<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Wiadomości</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="isCustomerActive()">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Odnotowano nowe wiadomości w otwartych zgłoszeniach</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newSignals.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj w widocznych"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newSignals.selected"
                                    :headers="newSignals.headers"
                                    :items="filteredSignals"
                                    item-key="identifier"
                                    :loading="loading"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage"
                                    :hide-default-footer="false"
                                    :server-items-length="totalItems"
                                    @update:page="handlePageUpdate"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                          <template v-slot:[`item.actions`]="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="showSignal(item)" v-on="on">
                                          <v-icon color="red">mdi-message-processing</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>ODCZYTAJ ZGŁOSZENIE</span>
                              </v-tooltip>
                          </template>

                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
        name: 'Messages',
        components: {
            ErrorSubscription
        },
  data() {
    return {
        newSignals: {
            selected: [],
            search: '',
            headers: [
                {
                    text: 'Identyfikator',
                    align: 'start',
                    sortable: false,
                    value: 'identifier',
                },
                { text: 'Data wpływu', value: 'datetime', sortable: false },
                { text: 'Dni', value: 'days', sortable: false },
                { text: 'Etykieta', value: 'title', sortable: false },
                { text: 'Kategoria', value: 'category', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            signals: [],
        },
        signals: [],
        itemsPerPage: 10,
        loading: true,
        totalItems: 20,
        pageNumber: 1,
    }
  },
    computed: {
        filteredSignals() {
            const searchText = this.newSignals.search.toLowerCase();
            return this.newSignals.signals.filter(signal => Object.values(signal).some(value => String(value).toLowerCase().includes(searchText)));
        },
    },
  async mounted() {
      try {

          if (this.isCustomerActive()) {
              this.main = (await Service.getMessagesOpenSignals(this.itemsPerPage, this.pageNumber)).data,
                  this.signalsDb = this.main.signals,
                  this.totalItems = this.main.totalItems,
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime', sortable: false },
                          { text: 'Dni', value: 'days', sortable: false },
                          { text: 'Etykieta', value: 'title', sortable: false },
                          { text: 'Kategoria', value: 'category', sortable: false },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.signalsDb,
                  },
                  this.loading = false
          }

        } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
  },
  watch: {
      itemsPerPage: {
          async handler() {
              //if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                  await this.loadRecords();
              //}
        },
        deep: true,
      },
    },
methods: {
    isCustomerActive() {
        return this.$store.state.admin.customerIsActive;
    },
    async handlePageUpdate(newPage) {
        this.pageNumber = newPage;
        await this.loadRecords();
    },
    showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id } })
    },
      async loadRecords() {
          try {
              this.loading = true;
              this.main = (await Service.getMessagesOpenSignals(this.itemsPerPage, this.pageNumber)).data;
              this.signalsDb = this.main.signals,
                  this.totalItems = this.main.totalItems,
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime', sortable: false },
                          { text: 'Dni', value: 'days', sortable: false },
                          { text: 'Etykieta', value: 'title', sortable: false },
                          { text: 'Kategoria', value: 'category', sortable: false },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.signalsDb,
                  };
              this.loading = false;
          } catch (e) {
              console.log(e)
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="../Reports/Basic.scss" lang="scss">
</style>
